import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import axios from 'axios'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/acl'
import '@/libs/vue-select'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/tour'

import '@/@fake-db/db'

// MANTENER SESION
require('@/store/:auth/subscriber')

// axios.defaults.baseURL = 'https://apilibrodigital.colegiospuchuncavi.cl/v1'
axios.defaults.baseURL = 'https://apilibrodigital.escuelarepublicadefrancia.cl/v1'
// axios.defaults.baseURL = 'https://apilibrodigital.colegiocataluña.cl/v1'
// axios.defaults.baseURL = 'https://apilibrodigital.colegsagradafamilia.cl/v1'

// axios.defaults.baseURL = 'https://apilibrodigital.softinnova.cl/v1'
// axios.defaults.baseURL = 'https://apidemo.librodigitalcertificado.cl/v1'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/v1'

// Cargar dotenv
// require('dotenv').config();
// console.log('API URL:', process.env.VUE_APP_API_URL);
// axios.defaults.baseURL = process.env.VUE_APP_API_URL

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = true

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
